<script setup>
import { ref, watch, computed } from "vue";
import moment from "moment";

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
});

const progress = ref(0);
const remainingDays = ref(0);

const getDaysText = computed(() => {
  if (remainingDays.value === 1) return "1 dia";
  if (remainingDays.value > 1) return `${remainingDays.value} dias`;
  return "Expirado";
});

function calculateProgress() {
  const startDate = moment(props.date);
  const endDate = startDate.clone().add(3, "days");
  const now = moment();

  remainingDays.value = now.isBefore(startDate)
    ? 0
    : now.isBefore(endDate)
    ? now.diff(startDate, "days") + 1
    : -1;

  if (now.isBefore(startDate)) {
    progress.value = 0;
  } else if (now.isAfter(endDate)) {
    progress.value = 100;
  } else {
    const totalDuration = endDate.diff(startDate, "hours");
    const elapsedDuration = now.diff(startDate, "hours");
    progress.value = Math.min(100, (elapsedDuration / totalDuration) * 100);
  }
}

watch(() => props.date, calculateProgress, { immediate: true });
</script>

<template>
  <section>
    <div class="circle">
      <div
        class="progress"
        :style="{ background: `conic-gradient(#3886F9 ${progress}% , #F0F2F5 0%)` }"
      />
      <div class="content">
        <img src="@/assets/img/icons/opportunities/clock.svg" alt="clock" />
        <h2>{{ getDaysText }}</h2>

        <span v-if="remainingDays > 0"> em aberto </span>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
section {
  position: relative;
  width: 230px;
  height: 230px;

  margin: auto;

  .circle {
    width: 230px;
    height: 230px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    .progress {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .content {
      position: absolute;

      display: grid;
      place-content: center;
      place-items: center;

      background-color: var(--gray-white);
      width: 93%;
      height: 93%;

      border-radius: 100%;

      img {
        margin-bottom: 20px;
        width: 25px;
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        color: var(--primary-800);
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-200);
      }
    }
  }
}
</style>
