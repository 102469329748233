<script setup>
import { computed } from "vue";
import { Description, Icon, Title } from "./base-components";
import { useStore } from "@/utils/useStore";
import moment from "moment";

const store = useStore();

const getOpportunity = computed(() => store.getters["opportunities/getOpportunitySelected"]);

function getOriginText(origin) {
  const texts = {
    PIX_NOT_PAID: "Não pago",
    BOLETO_NOT_PAID: "Não pago",
    CARD_DECLINED: "Recusado",
    INSUFICIENT_BALANCE: "Saldo insuficiente",
    ABANDONED_CART: "Abandonado",
  };
  return texts[origin] || origin;
}

function getSaleMethod(sale) {
  if (!sale) return "";

  const texts = {
    CREDIT_CARD: "Cartão de crédito",
    DEBITCARD: "Cartão de débito",
    TWO_CREDIT_CARDS: "Dois cartões",
    BOLETO: "Boleto",
    PIX: "Pix",
    ABANDONED_CART: "Carrinho abandonado",
  };

  return texts[sale];
}
</script>

<template>
  <div class="section-origin-opportunity">
    <Icon>
      <img src="@/assets/img/icons/opportunities/origin.svg" alt="Origin opportunity" />
    </Icon>

    <div class="data">
      <div>
        <Description size="12"> Origem da oportunidade </Description>
        <Title> #{{ getOpportunity?.greenn_opportunity_id }} </Title>
      </div>

      <section>
        <div class="item" v-if="getOpportunity?.product?.paymentMethod">
          <Description> Método de pagamento </Description>
          <Title> {{ getSaleMethod(getOpportunity?.product?.paymentMethod) }} </Title>
        </div>

        <div class="item">
          <Description> Motivo da recusa </Description>
          <Title class="refusal">
            {{ getOriginText(getOpportunity?.reason) }}
          </Title>
        </div>

        <div class="item">
          <Description> Data de tentativa </Description>
          <Title>
            {{ moment(getOpportunity?.intentionDate).subtract(3, 'hours').format("DD/MM/YYYY [às] HH:mm") }}
          </Title>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-origin-opportunity {
  display: flex;
  align-items: flex-start;
  gap: 70px;

  padding-left: 25px;
  margin-top: 80px;

  .data {
    width: 100%;
    section {
      display: grid;
      gap: 10px;

      margin-top: 20px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          font-weight: 600 !important;
          font-size: 14px !important;
        }

        .refusal {
          color: #ff4820;
        }
      }
    }
  }
}
</style>
