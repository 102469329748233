<script setup>
import ButtonFilters from "@/components/Views/Opportunities/filters/buttons/ButtonFilter.vue";
import iconContact from "@/assets/img/icons/opportunities/contact.svg";

import { useStore } from "@/utils/useStore";
import { computed } from "vue";

const store = useStore();

const getOpportunity = computed(() => store.getters["opportunities/getOpportunitySelected"]);

function redirectWhatsApp() {
  const phone = getOpportunity.value.lead?.phone;

  if (!phone) {
    console.error("Número de telefone não encontrado!");
    return;
  }

  const cleanPhone = phone.replace(/[^0-9]/g, "");

  if (cleanPhone.length < 10) {
    console.error("Número de telefone inválido!");
    return;
  }

  const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${cleanPhone}`;

  window.open(whatsappWebUrl, "_blank");
}
</script>

<template>
  <ButtonFilters @click="redirectWhatsApp" :img="iconContact" title="Contato" />
</template>
