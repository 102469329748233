<script setup>
import { computed } from "vue";
import { Description, Icon, Title, Copy } from "./base-components";

import { useStore } from "@/utils/useStore";

const store = useStore();
const getLinks = computed(() => store.getters["opportunities/getOpportunitySelected"]);

</script>

<template>
  <div class="section-origin-opportunity">
    <Icon>
      <img src="@/assets/img/icons/opportunities/link.svg" alt="Links" />
    </Icon>

    <div class="data">
      <div>
        <Description size="12"> {{ getLinks?.length }} links disponíveis </Description>
        <Title> Links úteis </Title>
      </div>
      
      <section>
        <div class="item" v-if="getLinks?.saleCheckout">
          <Description> Link do checkout para nova tentativa </Description>
          <Copy :text="getLinks?.saleCheckout" />
        </div>

        <div class="item" v-if="getLinks?.salesPage">
          <Description> Página de vendas </Description>
          <Copy :text="getLinks?.salesPage" />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-origin-opportunity {
  display: flex;
  align-items: flex-start;
  gap: 70px;

  padding-left: 25px;
  margin-top: 80px;

  .data {
    width: 100%;
    section {
      display: grid;
      gap: 28px;

      margin-top: 20px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          font-weight: 600 !important;
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
