<script setup>
import { ref } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const isCopy = ref(false);

function copyToClipboard() {
  navigator.clipboard.writeText(props.text);
  isCopy.value = true;

  setTimeout(() => {
    isCopy.value = false;
  }, 3000);
}
</script>

<template>
  <button class="copy-link" @click="copyToClipboard">
    <img v-if="!isCopy" src="@/assets/img/icons/opportunities/copy.svg" alt="Copy" />

    <span v-if="!isCopy"> Copiar link </span>
    <span v-else> Copiado! </span>
  </button>
</template>

<style lang="scss" scoped>
.copy-link {
  display: flex;
  align-items: center;
  gap: 10px;

  outline: none;
  border: none;
  background-color: transparent;

  font-size: 12px;
  font-weight: 600;
  color: var(--primary-800);
}
</style>
