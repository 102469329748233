<script setup>
import { computed, inject, ref } from "vue";

const id = Symbol();
const activeCollapsible = inject("activeCollapsible");
const setActiveCollapsible = inject("setActiveCollapsible");

const props = defineProps({
  index: {
    type: Number,
    default: 0,
    required: false,
  },
  qaId: {
    type: String,
    default: "action_open_collapsible",
  },
});

const emit = defineEmits(["change"]);

const isActive = computed(() => activeCollapsible.value === id);

function toggle(e) {
  if (e.target.classList.contains("skip-charge")) {
    return;
  }

  setActiveCollapsible(isActive.value ? null : id);
  emit("change", { index: props.index, value: isActive.value });
}
</script>

<template>
  <div @click="toggle" class="gr-collapse-item" :class="{ active: isActive }">
    <div class="gr-collapse-header-content">
      <slot name="header"></slot>
      <div>
        <img
          :id="qaId"
          src="@/assets/img/icons/opportunities/arrow-collapse-question.svg"
          alt="Arrow"
          class="icon"
        />
      </div>
    </div>

    <div class="gr-collapse-content" @click.stop>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gr-collapse-item {
  position: relative;
  width: var(--w-full);
  border-radius: 10px;
  border: 1px solid #e5e7e7;
  padding: var(--spacing-6);
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  .gr-collapse-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    transition: all 0.2s ease-in-out;
    transform: rotate(-180deg);
  }

  &.active {
    .icon {
      transform: rotate(0deg);
    }

    .gr-collapse-content {
      max-height: var(--w-full);
    }
  }
}

.gr-collapse-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  cursor: text;
  user-select: text;
}
</style>
