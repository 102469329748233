<script setup>
import { computed } from "vue";
import Counter from "./Counter.vue";
import Timeline from "./Timeline.vue";
import { useStore } from "@/utils/useStore";
import moment from "moment";

const store = useStore();

const getOpportunity = computed(() => store.getters["opportunities/getOpportunitySelected"]);

const initialDate = computed(() => {
  const createdAt = getOpportunity.value?.created_at;
  return moment(createdAt).format("DD/MM/YYYY [às] HH:mm");
});

const expirationDate = computed(() => {
  const createdAt = getOpportunity.value?.created_at;
  return moment(createdAt).add(3, "days").format("DD/MM/YYYY");
});
</script>

<template>
  <div class="grid-content-column">
    <aside>
      <div class="counter">
        <div class="nav">
          <img src="@/assets/img/icons/opportunities/clock-nav.svg" alt="clock" />
          <h2>Contador</h2>
        </div>

        <Counter :date="getOpportunity?.created_at || ''" />

        <div class="footer">
          <p>Iniciado em</p>
          <span>{{ initialDate }}</span>

          <p>Prazo final</p>
          <span>{{ expirationDate }}</span>
        </div>
      </div>

      <div class="timeline">
        <Timeline :timeline="getOpportunity?.timelines || []" />
      </div>
    </aside>
  </div>
</template>

<style lang="scss" scoped>
@mixin size($breakpoint) {
  @if $breakpoint == "sm" {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

.grid-content-column {
  grid-column: span 4 / span 4;

  @include size(lg) {
    display: none;
  }
}
aside {
  display: grid;
  gap: 30px;
  width: 100%;
  padding-top: 60px;

  position: sticky;
  top: 0;

  .counter {
    width: 100%;

    border-radius: 10px;
    background-color: var(--gray-white);

    border: 1px solid var(--gray-50);
    box-shadow: 2px 4px 30px 0px #0000000d;

    padding: 25px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    .nav {
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      margin-top: 30px;

      p {
        font-size: 15px;
        font-weight: 400;
        color: var(--gray-200);
        text-wrap: nowrap;
      }

      span {
        font-size: 15px;
        font-weight: 400;
        text-align: end;
        color: var(--primary-800);
        text-wrap: nowrap;
      }
    }
  }

  .timeline {
    width: 100%;
    display: grid;
    gap: 10px;

    section {
      width: 100%;
      height: 90px;
      background-color: var(--gray-white);
      border: 1px solid var(--gray-10);
      border-radius: 10px;
    }
  }
}
</style>
