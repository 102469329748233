<script setup>
import { computed, inject } from "vue";

const props = defineProps({
  question: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
});

const computedQuestion = computed(() => props.question.question);
const computedAnswer = computed(() => props.question.answer);
const showAnswer = computed(() => selectedAnswer.value == props.index);

function setSelectedAnswer() {
  selectedAnswer.value = props.index;
}

const selectedAnswer = inject("selectedAnswer");
</script>

<template>
  <div class="separator-question" :class="{ 'last-question': index === total - 1 }">
    <div>
      <div @click="setSelectedAnswer" class="header-question">
        <span class="name">{{ computedQuestion }}</span>

        <img
          src="@/assets/img/icons/opportunities/arrow-collapse-question.svg"
          alt="Arrow"
          :class="{ open: showAnswer }"
        />
      </div>

      <p class="answer" v-if="showAnswer">{{ computedAnswer }}</p>
    </div>

    <div class="separator" />
  </div>
</template>

<style lang="scss" scoped>
.separator-question {
  gap: var(--spacing-10);
  width: 100%;

  .header-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;

    height: 90px;

    .name {
      color: var(--primary-800);
      font-weight: var(--weight-semibold);
      font-size: var(--font-md);
    }

    img {
      transform: rotate(180deg);
    }

    .open {
      transition: all 0.3s;
      transform: rotate(00deg);
    }
  }
}

.answer {
  color: var(--gray-200);
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;

  margin-top: -12px !important;
  padding-bottom: 20px;

  overflow: hidden;
}

.separator {
  height: 0.5px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: var(--gray-50);
}

.last-question {
  .header-question {
    height: 70px !important;
    .name,
    img {
      margin-top: 20px;
    }
  }

  .answer {
    padding: 0 !important;
    margin-top: 5px !important;
  }

  .separator {
    display: none;
  }
}
</style>
