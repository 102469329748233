<script setup>
import ButtonFilters from "@/components/Views/Opportunities/filters/buttons/ButtonFilter.vue";
import iconStatus from "@/assets/img/icons/opportunities/status.svg";
import {
  GrModal,
  GrModalContent,
  GrModalHeader,
  GrModalFooter,
  GrModalClose,
} from "@/components/GrComponents/modal";

import {
  GrDropDown,
  GrDropDownTrigger,
  GrDropDownContent,
  GrDropDownItem,
  GrDropDownMenuGroup,
} from "@/components/GrComponents/dropdown";
import { computed, ref } from "vue";
import { GrButton } from "@/components/GrComponents";
import { useStore } from "@/utils/useStore";

const store = useStore();
const open = ref(false);
const type = ref("");

const getOpportunity = computed(() => store.getters["opportunities/getOpportunitySelected"]);
const isValidStatus = computed(() => {
  const status = getOpportunity.value?.status;
  return !(status === "LOST" || status === "NOT_APPLICABLE" || status === "WON");
});

function openModal(status) {
  open.value = !open.value;
  type.value = status;
}

async function updateStatus() {
  const data = {
    id: getOpportunity.value?.id,
    status: type.value,
  };
  await store.dispatch("opportunities/updateStatusOpportunity", data);

  open.value = false;
}
</script>

<template>
  <div>
    <GrDropDown>
      <GrDropDownTrigger :disabled="!isValidStatus">
        <ButtonFilters :img="iconStatus" title="Status" />
      </GrDropDownTrigger>

      <GrDropDownContent align="start" size="lg" class="content-status">
        <GrDropDownMenuGroup>
          <GrDropDownItem class="item" @click="openModal('IN_PROGRESS')">
            Em andamento
            <img src="@/assets/img/icons/opportunities/status-progress.svg" alt="Progress" />
          </GrDropDownItem>

          <GrDropDownItem class="item" @click="openModal('LOST')">
            Perdemos
            <img src="@/assets/img/icons/opportunities/status-lost.svg" alt="Lost" />
          </GrDropDownItem>
        </GrDropDownMenuGroup>
      </GrDropDownContent>
    </GrDropDown>

    <GrModal :modelValue="open" @update:modelValue="openModal">
      <GrModalContent size="sm">
        <GrModalHeader />

        <section class="content">
          <div class="icon">
            <img
              v-if="type === 'IN_PROGRESS'"
              src="@/assets/img/icons/opportunities/status-progress-color.svg"
              alt="Icon"
            />
            <img
              v-if="type === 'LOST'"
              src="@/assets/img/icons/opportunities/status-lost-color.svg"
              alt="Icon"
            />
          </div>

          <h2>Você está alterando o status</h2>

          <p>
            Se você continuar com esta ação, a alteração de status da oportunidade será definitivo,
            sem possibilidade de reversão.
          </p>
        </section>

        <GrModalFooter align="center">
          <GrModalClose class="button-close-status">
            <GrButton variant="gray-light"> Manter status </GrButton>
          </GrModalClose>

          <GrButton
            :variant="type === 'IN_PROGRESS' ? 'secondary' : 'red'"
            class="button-status"
            :class="{ 'button-status-lost': type === 'LOST' }"
            @click="updateStatus"
          >
            Alterar status
          </GrButton>
        </GrModalFooter>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style lang="scss" scoped>
.content-status {
  width: 273px !important;
  padding: 20px 10px !important;

  .item {
    justify-content: space-between;
    padding: 14px 10px;
    color: var(--gray-200);
  }
}

.button-status {
  background-color: #3886f9;
  &:hover {
    background-color: #3885f9d2 !important;
  }
}
.button-status-lost {
  background-color: #ff4820;

  &:hover {
    background-color: #ff4820d2 !important;
  }
}

.button-close-status,
.button-status-lost,
.button-status {
  width: 240px !important;
  button {
    width: 240px !important;
  }
}

.content {
  display: grid;
  place-items: center;

  gap: 15px;
  margin-bottom: 20px;

  .icon {
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: var(--primary-800);
    font-size: 20px;
    font-weight: 700;
  }

  p {
    color: var(--gray-200);
    font-size: 15px;
    font-weight: 400;
    padding: 0 40px;
    text-align: center;
  }
}

footer {
  padding-bottom: 20px;
}
</style>
