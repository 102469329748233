<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalFooter,
  GrModalHeader,
} from "@/components/GrComponents/modal";
import { GrButton } from "@/components/GrComponents/button";
import { GrInput } from "@/components/GrComponents/input";
import { GrTextarea } from "@/components/GrComponents/textarea";
import { computed, getCurrentInstance, defineProps, onMounted, ref, inject } from "vue";
import questionIcon from "@/assets/img/icons/opportunities/question.svg";
import { useStore } from "@/utils/useStore";

import ButtonFilters from "@/components/Views/Opportunities/filters/buttons/ButtonFilter.vue";
import iconQuestion from "@/assets/img/icons/opportunities/question.svg";

const instance = getCurrentInstance();
const vm = instance.proxy;
const store = useStore();

const props = defineProps({
  question: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const loading = ref(false);

const validations = ref({
  question: false,
  response: false,
});

const toast = (message, type) => {
  vm.$grToast.toast(message, {
    title: "Greenn Profit",
    variant: type,
    autoHideDelay: 5000,
    appendToast: true,
  });
};

const productId = computed(() => store.getters["opportunities/getOpportunitySelected"].product?.id);

const textQuestion = computed({
  get() {
    return store.getters["questions/getMakeQuestion"].question;
  },
  set(value) {
    store.dispatch("questions/setTextQuestion", value);
  },
});

const textResponse = computed({
  get() {
    return store.getters["questions/getMakeQuestion"].response;
  },
  set(value) {
    store.dispatch("questions/setTextResponse", value);
  },
});

const toUpdate = computed(() => {
  return props.question.hasOwnProperty("id");
});

async function onSubmit() {
  const isDataValid = await store.dispatch("questions/validMakeQuestion");
  const missingInput = Object.values(isDataValid).some((value) => value === true);

  if (missingInput) {
    validations.value = isDataValid;
    toast(
      "Por favor, preencha os campos corretamente: as perguntas devem conter pelo menos 5 caracteres e no maximo 300, e as respostas maximo 600.",
      "info"
    );
    return;
  }

  console.log({ productId });
  store.dispatch("questions/setProductId", productId.value);

  loading.value = true;

  let saveQuestion = null;

  try {
    if (toUpdate.value) {
      store.dispatch("questions/setQuestionId", props.question.id);
      saveQuestion = await store.dispatch("questions/setPutQuestion");
    } else {
      saveQuestion = await store.dispatch("questions/setPostQuestion");
    }
  } catch {
    toast(`Erro ao ${toUpdate.value ? "atualizar" : "criar"} pergunta`, "danger");
  }

  loading.value = false;

  if (saveQuestion) {
    toast(`Pergunta ${toUpdate.value ? "atualizada" : "criada"} com sucesso`, "success");
    vm.$refs.modalCreat._setupState.closeModal();
    store.dispatch("questions/setClearQuestions", {});
    return;
  }
}

function changeState() {
  validations.value = {
    question: false,
    response: false,
  };

  if (toUpdate.value) {
    store.dispatch("questions/setTextQuestion", props.question.question);
    store.dispatch("questions/setTextResponse", props.question.answer);
    return;
  }
  store.dispatch("questions/setClearQuestions");
}
</script>

<template>
  <div>
    <GrModal ref="modalCreat" @update:modelValue="changeState()">
      <template #open>
        <ButtonFilters :img="iconQuestion" title="Criar pergunta" />
      </template>

      <GrModalContent size="sm">
        <GrModalHeader />
        <div class="texts-info">
          <div class="icon-question">
            <img :src="questionIcon" alt="question" />
          </div>
          <h2>{{ toUpdate ? "Atualizar pergunta" : "Crie uma nova pergunta" }}</h2>
          <p>
            {{
              toUpdate
                ? "Preencha os campos abaixo para atualizar a pergunta."
                : "Preencha os campos abaixo para criar a pergunta. Você poderá editá-la depois."
            }}
          </p>
        </div>
        <div class="inputs-container">
          <GrInput
            name="input-question"
            type="text"
            :required="validations.question"
            :modelValue.sync="textQuestion"
            placeholder="Qual seria a pergunta?*"
          />
          <GrTextarea
            name="input-response"
            size="md"
            :required="validations.response"
            :modelValue.sync="textResponse"
            placeholder="Escreva a resposta"
            class="textarea-input"
          />
        </div>
        <GrModalFooter>
          <GrButton
            class="variant-button"
            variant="gray-dark"
            @click="onSubmit"
            :disabled="loading"
          >
            {{ toUpdate ? "Atualizar" : "Cadastrar" }} pergunta e resposta
          </GrButton>
        </GrModalFooter>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style lang="scss" scoped>
.variant-button {
  width: 100%;
  background: var(--primary-800);
}

.select-products {
  margin-bottom: var(--spacing-5-6);
}

.texts-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-5);
  width: 100%;

  h2 {
    font-size: var(--font-xl);
    font-weight: var(--weight-bold);
    line-height: var(--spacing-7);
    color: var(--primary-800);
    margin-bottom: var(--spacing-4);
  }

  p {
    white-space: normal;
    font-weight: var(--weight-normal);
    font-size: var(--font-sm);
    color: var(--gray-200);
    line-height: var(--spacing-7);
    text-align: center;
  }

  .icon-question {
    margin-bottom: var(--spacing-9);
    background-color: var(--gray-10);
    border-radius: var(--radius-full);
    padding: var(--spacing-5-6);

    img {
      width: var(--w-6);
      height: var(--w-6);
    }
  }
}

.inputs-container {
  width: 100%;
}
</style>

<style leng="scss">
.textarea-input {
  margin-bottom: 0 !important;

  .gr-input {
    margin-bottom: 0 !important;
  }
}

.variant-button {
  .gr-button-content {
    color: var(--gray-white);
  }
}

.required-select {
  .multiselect__tags {
    border-color: #d3140860 !important;

    #selectProducts::placeholder {
      color: #d3140860 !important;
    }
  }
}
</style>
