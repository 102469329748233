<script setup>
import { Description, Icon, Title, Copy } from "./base-components";

import { computed } from "vue";
import { useStore } from "@/utils/useStore";
import { money } from "@/utils/money";

const store = useStore();

const getOffers = computed(
  () => store.getters["opportunities/getOpportunitySelected"]?.productHasOffers
);
const getProduct = computed(() => store.getters["opportunities/getOpportunitySelected"]?.product);
</script>

<template>
  <div class="section-origin-opportunity">
    <Icon>
      <img src="@/assets/img/icons/opportunities/offers.svg" alt="Offers" />
    </Icon>

    <div class="data">
      <div>
        <Description size="12"> {{ getOffers?.lenght }} Ofertas disponíveis </Description>
        <Title> Ofertas </Title>
      </div>

      <section v-for="(item, index) in getOffers" :key="index">
        <div class="item">
          <Description>
            {{ !item?.default ? item?.name : "Preço base" }} * {{ money(item?.amount, "R$") }}
          </Description>
          <Copy :text="`https://payfast.greenn.com.br/${getProduct?.id}/offer/${item?.hash}`" />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-origin-opportunity {
  display: flex;
  align-items: flex-start;
  gap: 70px;

  padding-left: 25px;
  margin-top: 80px;

  .data {
    width: 100%;
    section {
      display: grid;
      gap: 28px;

      margin-top: 20px;

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          font-weight: 600 !important;
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
