import moment from "moment";

export function formatDate(dateAt) {
  const date = moment(dateAt);
  const now = moment();

  const diffInDays = now.diff(date, "days");

  const formattedDate = date.format("DD/MM/YYYY [às] HH:mm");
  const formattedTime = date.format("HH:mm");

  if (diffInDays === 0) return `Hoje às ${formattedTime}`;

  if (diffInDays === 1) return `Ontem às ${formattedTime}`;

  const daysAgoText = `${diffInDays} dias atrás •`;
  return `${daysAgoText} ${formattedDate}`;
}
