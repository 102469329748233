<script setup>
import { formatDate } from "@/utils/useDateFormat";
import { GrTag } from "@/components/GrComponents/tag";

import startOpportunityIcon from "@/assets/img/icons/opportunities/start-opportunity.svg";
import winIcon from "@/assets/img/icons/opportunities/win.svg";
import lostIcon from "@/assets/img/icons/opportunities/lost.svg";
import pendingIcon from "@/assets/img/icons/opportunities/pending.svg";
import notApplicable from "@/assets/img/icons/opportunities/not-applicable.svg";

const props = defineProps({
  timeline: {
    type: Array,
    required: true,
    default: () => [],
  },
});

function descriptionTimeline(status) {
  const texts = {
    WON: "Vencemos! Compra efetuada",
    IN_PROGRESS: "Vendedor iniciou a oportunidade",
    LOST: "Cliente desistiu da compra",
    NOT_APPLICABLE: "Não atuamos",
    PENDING: "Aguardando resposta",
  };
  return texts[status] || status;
}

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "info",
  };
  return variants[status] || "warning-light";
}

function getIconSrc(type) {
  const images = {
    IN_PROGRESS: startOpportunityIcon,
    WON: winIcon,
    LOST: lostIcon,
    NOT_APPLICABLE: notApplicable,
    PENDING: pendingIcon,
  };

  return images[type] || "";
}
</script>

<template>
  <div class="timeline" v-if="timeline.length > 0">
    <h2>Linha do tempo</h2>

    <ul v-for="(item, index) in timeline" :key="index" class="item">
      <li>
        <GrTag class="tag" :variant="getStatusVariant(item.type)">
          <img :src="getIconSrc(item.type)" />
        </GrTag>
        <div class="data">
          <h3>{{ descriptionTimeline(item.type) }}</h3>
          <span>{{ formatDate(item.updated_at) }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.timeline {
  display: grid;
  gap: 15px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-800);
  }

  .item {
    display: grid;
    border: 1px solid var(--gray-50);
    background-color: var(--gray-white);
    box-shadow: 2px 4px 30px 0px #0000000d;

    padding: 10px;
    border-radius: 10px;

    li {
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;

      .tag {
        width: 60px;
        height: 62px;

        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .data {
        display: grid;
        gap: 5px;

        h3 {
          font-size: var(--font-md);
          font-weight: var(--weight-semibold);
          color: var(--primary-800);
          text-wrap: nowrap;
        }
        span {
          font-size: var(--font-sm);
          font-weight: var(--weight-normal);
          color: var(--gray-200);
        }
      }
    }
  }
}
</style>
