<script setup>
import { computed } from "vue";
import { Description, Title } from "./base-components";
import { useStore } from "@/utils/useStore";

import imgEmpty from "@/assets/img/icons/img_null.svg";
import { money } from "@/utils/money";

const store = useStore();

console.log(store.getters);

const getProduct = computed(() => store.getters["opportunities/getOpportunitySelected"]?.product);
const getAmount = computed(() => store.getters["opportunities/getOpportunitySelected"]?.amount);

function typeCharge(type) {
  const texts = {
    TRANSACTION: "Valor único",
    SUBSCRIPTION: "Assinatura",
    CONTRACT: "Contrato",
  };

  return texts[type] || "";
}
</script>

<template>
  <div class="section-data-product">
    <div class="data">
      <img :src="getProduct?.image ? getProduct?.image : imgEmpty" :alt="getProduct?.name" />

      <section>
        <Description size="12"> {{ getProduct?.category }} </Description>
        <Title size="18"> {{ getProduct?.name }} </Title>
        <Description weight="600"> {{ money(getAmount, "R$") }} </Description>
      </section>
    </div>

    <div v-if="getProduct?.salesType">
      <Description size="12"> Tipo de cobrança </Description>
      <Title size="16"> {{ typeCharge(getProduct?.salesType) }} </Title>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section-data-product {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 40px;

  .data {
    display: flex;
    align-items: center;
    gap: 45px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      object-fit: cover;

      border: 1px solid var(--gray-50);
    }

    section {
      display: grid;
      gap: 5px;
    }
  }
}
</style>
