<script setup>
import {
  Header,
  Aside,
  DataProduct,
  OriginOpportunity,
  Links,
  Offers,
  Questions,
} from "@/components/Views/Opportunity";
import { GrCollapsible, GrCollapsibleItem } from "@/components/GrComponents/collaps";
import { Description, Title, Icon } from "@/components/Views/Opportunity/content/base-components";

import { useStore } from "@/utils/useStore";
import { ref, onMounted, computed, provide } from "vue";
import { useRoute } from "vue-router/composables";
import { GrSpinner } from "@/components/GrComponents/spinner";

const store = useStore();
const route = useRoute();

const loading = ref(false);
const indexAnswer = ref(false);

const getQuestions = computed(() => store.getters["opportunities/getOpportunitySelected"]);
const selectedAnswer = computed({
  get() {
    return indexAnswer.value;
  },
  set(index) {
    indexAnswer.value = index === indexAnswer.value ? false : index;
  },
});

async function getOpportunity() {
  loading.value = true;
  const idOpportunity = route.params && route.params?.id;

  try {
    await store.dispatch("opportunities/findUniqueOpportunity", idOpportunity);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await getOpportunity();
});

provide("selectedAnswer", selectedAnswer);
</script>

<template>
  <div v-if="!loading" class="container-opportunity">
    <div class="content">
      <Header />

      <div class="section-product-questions">
        <GrCollapsible>
          <GrCollapsibleItem :index="1">
            <template #header>
              <section class="header-product">
                <Icon>
                  <img src="@/assets/img/icons/opportunities/product.svg" alt="Produto" />
                </Icon>

                <div>
                  <Title> Produto </Title>
                  <Description>
                    Dados do produto, incluindo links, descrições e ofertas.
                  </Description>
                </div>
              </section>
            </template>
            <template #content>
              <DataProduct />

              <div class="separator" />

              <OriginOpportunity />

              <div class="separator" />

              <Links />

              <div class="separator" />

              <Offers />
            </template>
          </GrCollapsibleItem>
        </GrCollapsible>

        <GrCollapsible>
          <GrCollapsibleItem :index="2">
            <template #header>
              <section class="header-product">
                <Icon>
                  <img src="@/assets/img/icons/opportunities/question.svg" alt="Produto" />
                </Icon>

                <div>
                  <Title> Perguntas e respostas </Title>
                  <Description>
                    Confira as perguntas e respostas que podem ajudar a impulsionar as vendas.
                  </Description>
                </div>
              </section>
            </template>

            <template #content>
              <div v-if="getQuestions?.productQuestions?.length > 0" class="questions-list">
                <div v-for="(item, index) in getQuestions?.productQuestions" :key="index">
                  <Questions
                    :question="item"
                    :index="index"
                    :total="getQuestions?.productQuestions?.length"
                  />
                </div>
              </div>

              <p v-else class="empty-questions">0 perguntas registradas!</p>
            </template>
          </GrCollapsibleItem>
        </GrCollapsible>
      </div>
    </div>

    <Aside />
  </div>

  <div v-else class="container-loading">
    <section>
      <GrSpinner size="md" />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@mixin size($breakpoint) {
  @if $breakpoint == "sm" {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

.container-opportunity {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(12, minmax(0, 1fr)) 15px;
  overflow-y: scroll;
  max-width: 100%;

  .content {
    grid-column: span 8 / span 8;
    background-color: var(--gray-white);

    padding: 80px 60px;

    .section-product-questions {
      display: grid;
      gap: 15px;
      margin-top: 60px;

      .separator {
        height: 0.5px;
        width: 100%;
        position: absolute;
        left: 0;
        background-color: var(--gray-50);
        margin-top: 40px;
      }

      .header-product {
        display: flex;
        align-items: center;
        gap: 20px;

        div {
          display: grid;

          h2 {
            font-size: 16px;
            font-weight: 700;
            color: var(--primary-800);
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: var(--gray-200);
          }
        }
      }
    }
    @include size(lg) {
      padding: 40px;
    }
    @include size(sm) {
      padding: 20px;
    }
  }
  @include size(lg) {
    grid-column: span 13 / span 13;
    display: block !important;
  }
}

.container-loading {
  width: 100%;
  height: 100%;
  section {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.questions-list {
  margin-top: 10px;
}

.empty-questions {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-200);

  margin-top: 50px !important;
  display: flex;
  justify-content: center;
}
</style>

<style lang="scss">
@mixin size($breakpoint) {
  @if $breakpoint == "sm" {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1200px) {
      @content;
    }
  }
}

main:has(.container-opportunity) {
  background-color: #00181610 !important;
  width: calc(102dvw - 102px) !important;
  left: 90px !important;
  padding: 0 !important;

  @include size(lg) {
    background-color: var(--gray-white) !important;
  }

  @include size(md) {
    left: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }
}
</style>
