import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=b448759a&scoped=true"
import script from "./Counter.vue?vue&type=script&setup=true&lang=js"
export * from "./Counter.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Counter.vue?vue&type=style&index=0&id=b448759a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b448759a",
  null
  
)

export default component.exports